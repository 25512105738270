<template>
    <div class="container">
        <b-row>
            <b-col cols="12" sm="6" class="mb-4 mt-3">
                <h1>Toplama Noktaları</h1>
            </b-col>
            <b-col  cols="12" sm="6" class="text-right mt-2">
                <date-picker 
                    v-model="range" 
                    type="date" 
                    format="YYYY-MM-DD" 
                    value-type="YYYY-MM-DD"
                    placeholder="Tarih Seçin"
                    range-separator=" / "
                    confirm 
                    range 
                    class="date"
                    ref="datePicker"
                    confirm-text='Tamam'></date-picker>
            </b-col>
        </b-row>
        <b-col class="mt-2">
            <b-row>
                <b-table responsive striped hover :fields="fields" :items="firmalar">
                    <template v-slot:cell(firma_il)="il_verisi">
                        {{ilGetir(il_verisi.item.firma_il)}}
                    </template>
                    <template v-slot:cell(firma_ilce)="ilce_verisi">
                        {{ilceGetir(ilce_verisi.item.firma_ilce)}}
                    </template>
                    <template v-slot:cell(firma_sey)="firma">
                        <b-badge @click="sil(firma.item.firma_id)" size="sm" variant="danger"><b-icon-trash></b-icon-trash></b-badge>
                        <b-badge :to="'/firmaDetay/' + firma.item.firma_id" size="sm" class="ml-1"><b-icon-search></b-icon-search></b-badge>
                    </template>
                    <template v-slot:cell(ilgi)="ilgi">
                        <b-badge v-if="!ilgi.item.ilgi" size="sm" variant="danger"><b-icon-arrow-down></b-icon-arrow-down></b-badge>
                        <b-badge v-else size="sm" variant="primary"><b-icon-arrow-up></b-icon-arrow-up></b-badge>
                    </template>
                </b-table>
            </b-row>
        </b-col>
        <b-button variant="primary" class="ekleButton" to="/firmaForm">Yeni Ekle</b-button>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data(){
        return{
            fields : [
                {
                    "key" : "firma_unvan",
                    "label" : "Ünvan",
                    "sortable": "true"
                },
                // {
                //     "key" : "firma_ilce",
                //     "label" : "İlçe"
                // },
                // {
                //     "key" : "firma_il",
                //     "label" : "Şehir"
                // },
                {
                    "key": "firma_adres",
                    "label": "Adres"
                },
                {
                    "key" : "user_adsoyad",
                    "label" : "Toplayıcı"
                },
                // {
                //     "key" : "olmasi",
                //     "label" : "Gerçekleşen",
                //     "thStyle": { width: '30px'},
                // },
                {
                    "key" : "firma_sey",
                    "label" : "Düzenle",
                    "tdClass" : "text-right",
                    "thClass" : "text-right",
                    "thStyle": { width: '100px'},
                },
                {
                    "key" : "ilgi",
                    "label" : "Durum",
                    "thStyle": { width: '30px'},
                },
            ],
            rangeChange: null,
        }
    },


    computed : {
        firmalar(){
            return this.$store.state.firmalar;
        },
        iller(){
            return this.$store.state.iller;
        },
        ilceler(){
            return this.$store.state.ilceler;
        },
        days(){
            return this.$store.state.days;
        },
        range: {
            get(){
                return this.rangeChange === null ? [this.days.start, this.days.finish] : this.rangeChange;
            },
            set(val){
                this.rangeChange = val        	
            }
        }
    },


    methods : {
        sil(a){
            Swal.fire({
                title: 'Emin Misiniz?',
                text: "Seçtiğiniz firma veritabanından kalıcı olarak silinecektir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, eminim!',
                cancelButtonText: 'Hayır'
            })
            .then((result) => {
                if (result.value) {
                    this.$store.dispatch("firmaSil", a)
                    .then(r => {
                        Swal.fire(
                            r.title,
                            r.message,
                            r.status ? 'success' : 'error'
                        )
                    })
                }
            })
        },
        duzenle(a){
            Swal.fire({
                title: 'Firma Düzenlenecektir!',
                text: a + 'Seçtiğiniz firmayı düzenlemek istediğinize emin misiniz?',
                icon: 'warning'
            });
        },
        ilGetir(a){
            var ilkdeger = this.iller.filter(r => r.id == a);
            var deger = ilkdeger.map(r => {
                return r.id == a ? r.name : false;
            })
            return deger[0];
        },
        ilceGetir(a){
            var ilkdeger = this.ilceler.filter(r => r.id == a);
            var deger = ilkdeger.map(r => {
                return r.id == a ? r.name : false;
            })
            return deger[0]
        },
    },

    watch: {
        rangeChange(a){
            var deger = {
                start: a[0],
                finish: a[1],
            }
            this.veriGuncelle(deger);
        }
    }

}
</script>


<style scoped>
.badge:hover{
    cursor: pointer;
}
.ekleButton{
    position: fixed;
    right: 15px;
    bottom: 15px;
}
h1{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}
.breadcrumb {
    background: transparent;
    padding: 0;
    font-size: 14px;
}
.breadcrumb a{
    color: #333;
}
@media (max-width: 767px){
    .date{
        width: 100%;
    }
}
</style>